<template>
  <div class="setting-filiale-sci">
    <b-modal
      ref="addConfigurationModal"
      id="addConfigurationModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Dupliquer une configuration</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('addConfigurationModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitConfiguration"
            class="form-modal-custom-style"
          >
            <div class="actionModel">
              <div class="messageError">
                <div v-if="getConfigurationError" class="error">
                  <ul
                    v-if="Array.isArray(getConfigurationError)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li
                      v-for="(error, index) in getConfigurationError"
                      :key="index"
                    >
                      {{ error }}
                    </li>
                  </ul>
                  <div v-else>{{ getConfigurationError }}</div>
                </div>
                <div v-if="getErrorForSpecific" class="error">
                  <div class="d-flex flex-row justify-content-center">
                    <p>{{ getErrorForSpecific.error }}</p>
                    <div class="ml-2 bckground-icon-info">
                      <font-awesome-icon
                        class="info-icon"
                        :id="'tooltip-societe'"
                        icon="info"
                      />
                    </div>
                  </div>
                  <b-tooltip :target="'tooltip-societe'" triggers="hover">
                    <ul class="mb-0" style="list-style-type: none">
                      <li
                        v-for="(error, index) in getErrorForSpecific.acheteurs"
                        :key="index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </b-tooltip>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8">
                <div class="ModelCol mr-3">
                  <b-col sm="2" md="2">
                    <b-form-group
                      label="Famille : "
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group
                  ></b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToAdd.main_family"
                        name="some-radios"
                        value="support"
                        @change="initialFamilly"
                        class="check-th-details mt-2 mb-2"
                        >Support</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToAdd.main_family"
                        name="some-radios"
                        value="master"
                        @change="initialFamilly"
                        class="check-th-details mt-2 mb-2"
                        >Master</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToAdd.main_family"
                        @change="
                          initialFamilly;
                          configurationToAdd.filiale_id = 291;
                        "
                        name="some-radios"
                        value="commercial"
                        class="check-th-details mt-2 mb-2"
                        >Commercial</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToAdd.main_family == 'commercial'"
                >
                  <div class="col-lg-8">
                    <b-form-group
                      class="famillies-list-label"
                      label-cols-sm="6"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      label="Commercial *  : "
                    >
                      <multiselect
                        v-model="configurationToAdd.commercials"
                        :options="computedCommercial"
                        :multiple="true"
                        label="name"
                        track-by="id"
                        placeholder="sélectionner un commercial"
                        :showLabels="false"
                        class="multiselect-vue-custom-style-theme"
                        :loading="loadingFiliale"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        name="flavour-1"
                        id="checkbox-group-filter"
                        class="check-th"
                        @change="SelectAllCommercials($event)"
                        :checked="
                          computedSelectAllComercials(
                            configurationToAdd.commercials
                          )
                        "
                      >
                        Tous
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToAdd.main_family == 'support'"
                >
                  <div class="col-lg-8">
                    <b-form-group
                      class="famillies-list-label"
                      label-cols-sm="6"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      label="Famille Support: "
                    >
                      <multiselect
                        v-model="configurationToAdd.famillies"
                        :options="getListFamilleConfigFrias"
                        :multiple="true"
                        label="familly"
                        track-by="id"
                        placeholder="sélectionner une famille"
                        required
                        :showLabels="false"
                        :loading="getLoadingFamilleConfig"
                        class="multiselect-vue-custom-style-theme"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        name="flavour-1"
                        id="checkbox-group-filter"
                        class="check-th"
                        @change="SelectAllFamilles($event)"
                        :checked="
                          computedSelectAllFamilles(
                            configurationToAdd.famillies
                          )
                        "
                      >
                        Tous
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2">
                    <b-form-group
                      label=""
                      label-for=""
                      class="input-modal-champ mb-0"
                    >
                      <button
                        type="button"
                        v-b-modal.addConfigurationFamilleFraisModal
                        class="ml-2 btn-add-anay-prop mt-2"
                        title="Ajouter une famille"
                      >
                        <font-awesome-icon
                          icon="plus"
                          class="icon-plus-ciston-config-dync"
                        />
                      </button>
                    </b-form-group>
                  </div>
                </div>

                <div class="m-0"><hr /></div>
                <div class="ModelCol mr-3">
                  <b-col sm="3" md="3">
                    <b-form-group
                      label="Regroupement par : "
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group
                  ></b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToAdd.grouped"
                        name="some-radios"
                        @change="changeState"
                        value="dossier"
                        class="check-th-details mt-2 mb-2"
                        >Par dossier</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToAdd.grouped"
                        name="some-radios"
                        value="groupe"
                        class="check-th-details mt-2 mb-2"
                        @change="changeState"
                        >Par groupe</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group>
                  </b-col>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToAdd.grouped == 'groupe'"
                >
                  <b-form-group
                    label="Grouper par"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <select-simple-with-search
                      :value="configurationToAdd.group_by"
                      :options="computedGetColumns"
                      placeholder="Groupe par"
                      @changeValue="configurationToAdd.group_by = $event"
                      label="value"
                      trackBy="key"
                      noResult="Aucun Groupe trouvé."
                    />
                  </b-form-group>
                  <!-- bureau d'etude -->
                  <b-form-group
                    label="Facture grouper par :"
                    label-for="be-name"
                    class="input-modal-champ mb-0"
                  >
                    <select-simple-with-search
                      :value="configurationToAdd.facture_group_by"
                      :options="computedGetColumns"
                      placeholder="Facture groupe par"
                      @changeValue="
                        configurationToAdd.facture_group_by = $event
                      "
                      label="value"
                      trackBy="key"
                      noResult="Aucun option trouvé."
                    />
                  </b-form-group>
                </div>
                <div class="m-0"><hr /></div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToAdd.main_family == 'commercial'"
                >
                  <b-col sm="2" md="2">
                    <b-form-group
                      label="Appel à paiement : "
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group
                  ></b-col>
                  <b-col sm="3" md="3">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ"
                    >
                      <b-form-checkbox
                        v-model="configurationToAdd.payment_call"
                        name="some-radios"
                        class="check-th-details mt-2 mb-2"
                        >Appel à paiement</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="7" md="7"></b-col>
                </div>
                <div
                  class="m-0"
                  v-if="configurationToAdd.main_family == 'commercial'"
                >
                  <hr />
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Société à configurer"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <select-simple-with-search
                      :value="configurationToAdd.filiale_id"
                      :options="computedNotCommercial"
                      placeholder="sélectionner une Sociéte"
                      @changeValue="configurationToAdd.filiale_id = $event"
                      :disabled="
                        configurationToAdd.main_family == 'commercial'
                          ? true
                          : false
                      "
                    />
                  </b-form-group>
                  <!-- bureau d'etude -->
                  <b-form-group
                    label="Type"
                    label-for="be-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="be-name"
                      v-model="configurationToAdd.acheteur_type"
                      value-field="id"
                      text-field="name"
                      :options="acheteur_type_list"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <!-- getFilialsSupport -->
                  <b-form-group
                    label="Client"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                    v-if="configurationToAdd.acheteur_type == 'frais'"
                  >
                    <select-simple-with-search
                      :value="configurationToAdd.acheteur_id"
                      :options="computedNotCommercial"
                      placeholder="sélectionner un client"
                      @changeValue="
                        configurationToAdd.acheteur_id = $event;
                        checkErrorsForAdd();
                      "
                      noResult="Aucun Client trouvé."
                    />
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Début de configuration"
                    label-for="Début de configuration"
                    class="input-modal-champ mb-0 pl-0"
                  >
                    <date-picker
                      format="DD-MM-YYYY"
                      value-type="format"
                      type="date"
                      required
                      v-model="configurationToAdd.date_debut"
                      :clear-button="true"
                      @input="checkErrorsForAdd"
                      :disabled-date="disabledStartDate"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group
                    label="Fin du configuration"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <date-picker
                      format="DD-MM-YYYY"
                      value-type="format"
                      type="date"
                      v-model="configurationToAdd.date_fin"
                      @change="checkErrorsForAdd"
                      :clear-button="true"
                      :disabled-date="disabledEndDate"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="TVA"
                    label-for="TVA"
                    class="input-modal-champ mb-0 col-4 pl-0"
                  >
                    <b-form-select
                      id="tva"
                      v-model="configurationToAdd.tva"
                      required
                      :options="getAllTvas"
                      class="b-form-select-raduis"
                      text-field="valeur"
                      value-field="valeur"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TVA %"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label=""
                    label-for=""
                    class="input-modal-champ mb-0 col-2"
                  >
                    <button
                      type="button"
                      v-b-modal.addtvaModalConfigFrais
                      class="ml-2 btn-add-anay-prop"
                      title="Ajouter une nouvelle tva"
                    >
                      <font-awesome-icon
                        icon="plus"
                        class="icon-plus-ciston-config-dync"
                      /></button
                  ></b-form-group>
                  <b-form-group
                    label="Mode de calcul"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0 col-6 pl-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToAdd.coef_type"
                      :options="coef_type_reduction_options"
                      required
                      class="b-form-select-raduis"
                      @change="checkErrorsForAdd"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Description"
                    label-for="desc-config"
                    class="input-modal-champ mb-0"
                  >
                    <b-textarea
                      autocomplete="off"
                      id="desc-config"
                      v-model="configurationToAdd.description"
                      class="b-form-select-raduis"
                    ></b-textarea>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Description"
                    label-for="desc-config"
                    class="input-modal-champ mb-0"
                  >
                    <b-textarea
                      autocomplete="off"
                      id="desc-config"
                      v-model="configurationToAdd.facture_description"
                      class="b-form-select-raduis"
                    ></b-textarea>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Réduction"
                    label-for="Coefficient"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      v-model="configurationToAdd.reduction"
                      required
                      type="number"
                      step="any"
                      min="0"
                      oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                      class="b-form-select-raduis"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Type de réduction"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToAdd.reduction_type"
                      required
                      :options="coef_type_reduction_options"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    :label="
                      configurationToAdd.coef_type == 'pourcentage'
                        ? 'Total de Coefficient'
                        : 'Total du Montant'
                    "
                    label-for="Coefficient"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToAdd.coef"
                      required
                      type="number"
                      step="any"
                      class="b-form-select-raduis"
                      :disabled="computedDisabledCoeffition"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Objet"
                    label-for="label"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToAdd.label"
                      required
                      class="b-form-select-raduis"
                      @input="checkErrorsForAdd"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div
                  v-for="(coeff, index) in configurationToAdd.coefs"
                  :key="index"
                >
                  <div class="ModelCol mr-3">
                    <b-form-group
                      :label="
                        configurationToAdd.coef_type == 'pourcentage'
                          ? 'Coefficient'
                          : 'Montant'
                      "
                      label-for="Coefficient"
                      class="input-modal-champ mb-0 pl-0 col-6"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.coef"
                        required
                        type="number"
                        step="any"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Label"
                      label-for="label"
                      class="input-modal-champ mb-0 pl-0 col-6"
                    >
                      <!-- <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.label"
                        required
                        class="b-form-select-raduis"
                      ></b-form-input> -->
                      <b-textarea
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.label"
                        required
                        class="b-form-select-raduis"
                      ></b-textarea>
                    </b-form-group>
                    <b-form-group class="input-modal-champ mb-0">
                      <b-button
                        v-if="index > 0"
                        class="button-danger-style ml-1 float-left mt-4"
                        size="sm"
                        variant="danger"
                        @click="deleteCoefficient(index, 'add')"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-form-group>
                  </div>
                </div>
                <div>
                  <b-form-group
                    label=""
                    label-for=""
                    class="input-modal-champ mb-0"
                  >
                    <button
                      type="button"
                      @click="addNewcoeffition('add')"
                      class="ml-2 btn-add-anay-prop"
                    >
                      <font-awesome-icon
                        icon="plus"
                        class="icon-plus-ciston-config-dync"
                      />
                    </button>
                  </b-form-group>
                </div>
                <hr />
                <div
                  v-if="
                    configurationToAdd.coef_type === 'pourcentage' ||
                      configurationToAdd.coef_type === 'prix unitaire'
                  "
                >
                  <div class="d-flex justify-content-start text-dark mt-1">
                    <p><b>Formules:</b></p>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-button
                      variant="success"
                      class="config-btn d-flex justify-content-start ml-2"
                      @click="addNewFormule('add')"
                      ><font-awesome-icon icon="plus" /> Ajouter une
                      formule</b-button
                    >
                    <div>
                      <b-form-group
                        v-if="configurationToAdd.formules.length > 1"
                        label=""
                        label-for=""
                        class="
                          input-modal-champ
                          mb-0
                          ml-2
                          d-flex
                          justify-content-start
                        "
                      >
                        <b-form-select
                          autocomplete="off"
                          id="and_or"
                          v-model="configurationToAdd.formule_types"
                          required
                          :options="formule_types_options"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group
                        label="Colonne"
                        label-for="colonne"
                        class="
                          input-modal-champ
                          mb-0
                          ml-2
                          d-flex
                          justify-content-start
                        "
                      >
                        <b-form-select
                          autocomplete="off"
                          :id="'condition_type'"
                          @input="CopyFields($event)"
                          :options="getNumeriqCulomn"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div>
                      <div class="CopieMsg" v-if="isTextCopied">
                        Text copier
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(formu, index) in configurationToAdd.formules"
                    :key="index"
                  >
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Formule"
                        label-for="formule"
                        class="input-modal-champ mb-0 col-lg-11"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="formu.formule"
                          rows="3"
                          required
                          class="b-form-select-raduis"
                          @keyup="checkFormuleConfigFraisDynamic('add', index)"
                        ></b-form-input>
                        <em class="text-danger">{{ formu.error }} </em>
                      </b-form-group>

                      <b-form-group class="input-modal-champ mb-0 col-lg-1">
                        <b-button
                          class="button-danger-style mt-4"
                          size="sm"
                          variant="danger"
                          @click="deleteFormule(index, 'add')"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </b-form-group>
                      <hr />
                    </div>
                  </div>
                  <div v-if="configurationToAdd.formules.length">
                    <div class="d-flex justify-content-start text-dark mt-3">
                      <p>
                        <b class="mr-2">Formules Finale:</b
                        ><em>
                          {{ computedFormule(configurationToAdd) }}
                        </em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <p class="d-flex justify-content-start text-dark">
                  <b> Condition de configuration</b>
                </p>
                <div class="ModelCol mr-3">
                  <b-button
                    variant="success"
                    class="config-btn d-flex justify-content-start ml-2"
                    @click="addNewCondition('add')"
                    ><font-awesome-icon icon="plus" /> Ajouter une
                    condition</b-button
                  >
                  <div>
                    <b-form-group
                      v-if="configurationToAdd.conditions.length > 1"
                      label=""
                      label-for="filiale-name"
                      class="
                        input-modal-champ
                        mb-0
                        ml-2
                        d-flex
                        justify-content-start
                      "
                    >
                      <b-form-select
                        autocomplete="off"
                        id="and_or"
                        v-model="configurationToAdd.condition_types"
                        required
                        :options="and_or_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div
                  v-for="(condition, index) in configurationToAdd.conditions"
                  :key="index"
                >
                  <div class="ModelCol mr-3">
                    <p class="m-0" hidden>
                      {{ (condition.index = index) }}
                    </p>
                    <b-form-group
                      label="Colonne"
                      label-for="colonne"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        :value="condition.column"
                        :options="computedGetColumns"
                        placeholder="colonne"
                        @changeValue="
                          condition.column = $event;
                          condition.value = checkColomnConditionConfig(
                            condition.column
                          )
                            ? null
                            : condition.value;
                        "
                        label="value"
                        trackBy="key"
                        :title="conputedSelectedColumn(condition.column)"
                        noResult="Aucune colonne trouvé."
                      />
                    </b-form-group>
                    <b-form-group
                      label="Operation"
                      label-for="Operation"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-name"
                        v-model="condition.operation"
                        required
                        :options="operation_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      v-if="
                        condition.operation != 'ISNULL' &&
                          condition.operation != 'ISNOTNULL'
                      "
                      label="Valeur"
                      label-for="Valeur"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        v-if="checkColomnConditionConfig(condition.column)"
                        :value="condition.value"
                        :options="
                          condition.column == 'organisme'
                            ? getListOrganismeObligee
                            : getProFilials
                        "
                        placeholder="valeur"
                        @changeValue="condition.value = $event"
                        label="name"
                        trackBy="name"
                        noResult="Aucun Valeur trouvé."
                      />
                      <b-form-input
                        v-else
                        autocomplete="off"
                        v-model="condition.value"
                        required
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="input-modal-champ mb-0">
                      <b-button
                        class="button-danger-style mt-4"
                        size="sm"
                        variant="danger"
                        @click="deleteCondition(index, 'add')"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-form-group>
                  </div>
                  <hr />
                </div>

                <div
                  v-if="
                    configurationToAdd.filiale_id != null &&
                      configurationToAdd.date_debut != null
                  "
                >
                  <div class="d-flex justify-content-start text-dark mt-1">
                    <p><b>Specifics:</b></p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <b-form-checkbox
                      v-model="checkAll"
                      switch
                      @change="changeAllCheckColumns"
                      >Tous</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="specific_master"
                      switch
                      @change="changeMastersFormmat('add')"
                      >Master</b-form-checkbox
                    >
                    <b-form-group
                      label=""
                      label-for="search-add"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="search"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <br />
                  <b-form-group
                    label=""
                    v-slot="{ ariaDescribedby }"
                    class="d-block"
                  >
                    <div class="custom-class-fields-av scroll-specifics-params">
                      <b-form-checkbox
                        v-for="(option, index) in computedSearchListSpecifics"
                        :key="index"
                        v-model="configurationToAdd.specifics"
                        :aria-describedby="ariaDescribedby"
                        :value="{ filiale_id: option.filiale_id }"
                        @change="checkErrorsForAdd(option)"
                      >
                        {{ option.name }}
                        <template v-if="specific_master && option.master">
                          <b-form-checkbox
                            v-for="(master, indexM) in option.societe_master"
                            :key="'M' + indexM"
                            v-model="configurationToAdd.specifics"
                            :aria-describedby="ariaDescribedby"
                            :value="{ filiale_id: master.id }"
                            @change="checkErrorsForAdd"
                          >
                            {{ master.name }}</b-form-checkbox
                          >
                        </template>
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="actionModel">
              <div class="messageError" v-if="errorConfig">
                <div class="error">
                  {{ errorConfig }}
                </div>
              </div>
              <!-- <div class="messageError">
                    <div v-if="getConfigurationError" class="error">
                      <ul
                        v-if="Array.isArray(getConfigurationError)"
                        class="mb-0"
                        style="list-style-type: none;"
                      >
                        <li
                          v-for="(error, index) in getConfigurationError"
                          :key="index"
                        >
                          {{ error }}
                        </li>
                      </ul>
                      <div v-else>{{ getConfigurationError }}</div>
                    </div>
                    <div v-if="getErrorForSpecific" class="error">
                      <div class="d-flex flex-row justify-content-center">
                        <p>{{ getErrorForSpecific.error }}</p>
                        <div class="ml-2 bckground-icon-info ">
                          <font-awesome-icon
                            class="info-icon"
                            :id="'tooltip-societe'"
                            icon="info"
                          />
                        </div>
                      </div>
                      <b-tooltip :target="'tooltip-societe'" triggers="hover">
                        <ul class="mb-0" style="list-style-type: none;">
                          <li
                            v-for="(error,
                            index) in getErrorForSpecific.acheteurs"
                            :key="index"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </b-tooltip>
                    </div>
                  </div> -->
              <b-button
                type="submit"
                class="button-valide-style"
                :disabled="getIsLoadingConfigFraisTh"
              >
                <!-- !validateToAddconf || -->
                <span>
                  Valider
                  <div v-if="getIsLoadingConfigFraisTh" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- <div class="ligne-box-setting-header"></div> -->
    <div class="box-all-filter mb-1">
      <div class="box-label-champ mr-2">
        <div class="label-box-style w-93-px">
          <span class="title-tabel">Date début</span>
        </div>
        <!-- :clearable="false"
            :editable="false" -->
        <date-picker
          value-type="format"
          type="date"
          v-model="dateStartforSearch"
          :clear-button="true"
          @input="searchFilter"
          :disabled-date="disabledStartDateFilter"
          input-class="custom-date-picker-filter"
          class="custom-date-picker-calender-filter"
        ></date-picker>
      </div>
      <div class="box-label-champ mr-2">
        <div class="label-box-style w-93-px">
          <span class="title-tabel">Date fin</span>
        </div>
        <date-picker
          input-class="custom-date-picker-filter"
          class="custom-date-picker-calender-filter"
          value-type="format"
          type="date"
          v-model="dateEndForSearch"
          :clear-button="true"
          @input="searchFilter"
          :disabled-date="disabledEndDateFiltre"
        ></date-picker>
      </div>
      <filter-component
        label="Date active"
        classLabel="label-box-style w-78-px"
      >
        <template v-slot:body>
          <date-picker
            value-type="format"
            type="date"
            v-model="active_date"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
            @change="handelFilter"
          ></date-picker
        ></template>
      </filter-component>

      <div class="box-end-position">
        <div class="Search-style-component">
          <div class="search-box">
            <font-awesome-icon icon="search" class="icon" />

            <input
              @input="searchFilter"
              v-model="nom_societe"
              class="search-input"
              placeholder="Chercher"
            />
          </div>
        </div>
        <b-button
          size="sm"
          class="button-export-style ml-2 mr-2"
          title="Filter"
          id="popover-target-1"
        >
          <font-awesome-icon icon="filter" />
        </b-button>
        <b-popover
          triggers="focus"
          target="popover-target-1"
          placement="top"
          custom-class="my-custom-class-popover-filter"
        >
          <filter-component label="Société" classLabel="w-78-px" class="mb-2">
            <template v-slot:body>
              <multiselect
                v-model="vendeurs"
                :options="[...computedNotCommercial]"
                :multiple="true"
                label="name"
                track-by="id"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner"
                @input="searchFilter"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length
                    }}{{
                      values.length == 1
                        ? ' Société'
                        : values.length > 1
                        ? ' Sociétés'
                        : ''
                    }}</span
                  ></template
                >
              </multiselect>
            </template></filter-component
          >
          <filter-component label="Famille" classLabel="w-78-px" class="mb-2">
            <template v-slot:body>
              <b-form-select
                class="b-form-select-new-style b-form-select-raduis-border "
                v-model="famille"
                :options="[{ value: null, text: '' }, ...computedListFamille]"
                @change="handelFilter"
              ></b-form-select></template
          ></filter-component>
          <filter-component
            label="Commercial"
            classLabel="w-78-px"
            class="mb-2"
            v-if="famille == 'commercial'"
          >
            <template v-slot:body>
              <multiselect
                v-model="commercials"
                :options="[...computedCommercial]"
                :multiple="true"
                label="name"
                track-by="id"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner"
                @input="searchFilter"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length
                    }}{{
                      values.length == 1
                        ? ' Commercial'
                        : values.length > 1
                        ? ' Commerciaux'
                        : ''
                    }}</span
                  ></template
                >
              </multiselect>
            </template></filter-component
          >
          <filter-component label="Active" classLabel="w-78-px" class="mb-2">
            <template v-slot:body>
              <b-form-select
                class="b-form-select-new-style b-form-select-raduis-border "
                v-model="active"
                :options="[
                  { value: null, text: 'Tous' },
                  { value: 'non', text: 'Non' },
                  { value: 'oui', text: 'Oui' }
                ]"
                text-field="text"
                value-field="value"
                @change="handelFilter"
              ></b-form-select></template
          ></filter-component>
        </b-popover>
      </div>
      <div class="title loaderPosition">
        <div v-if="getIsLoadingConfigFraisTh" class="loading mt-1 ml-2">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="
          dateStartforSearch !== null ||
            dateEndForSearch !== null ||
            active_date !== null ||
            famille !== null ||
            active !== null ||
            (nom_societe !== null && nom_societe !== '')
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont : </span>
        <span class="item-filter-style" v-if="dateStartforSearch !== null"
          >Date début : {{ dateStartforSearch }}</span
        >
        <span class="item-filter-style" v-if="dateEndForSearch !== null"
          >Date fin : {{ dateEndForSearch }}</span
        >
        <span class="item-filter-style" v-if="active_date !== null"
          >Date active : {{ active_date }}</span
        >
        <span class="item-filter-style" v-if="famille !== null"
          >Famille : {{ famille }}</span
        >
        <span class="item-filter-style" v-if="active !== null"
          >Active : {{ active }}</span
        >
        <span
          v-if="nom_societe !== null && nom_societe !== ''"
          class="item-filter-style"
          >Recherche : {{ nom_societe }}</span
        >
      </div>
    </div>
    <div class="chip-filters-simulation">
      <div v-if="vendeurs && vendeurs.length" class="block-filter">
        <span class="title-block-chip">Société : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="vendeurs"
          @input="searchFilter"
        >
          <vs-chip
            :key="vd.id + 'master-filiale'"
            @click="remove(vd, 'vendeurs', 'searchFilter')"
            v-for="vd in vendeurs"
            closable
          >
            {{ vd.name }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="chip-filters-simulation">
      <div v-if="commercials && commercials.length" class="block-filter">
        <span class="title-block-chip">Commerciaux : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="commercials"
          @input="searchFilter"
        >
          <vs-chip
            :key="vd.id + 'master-filiale'"
            @click="remove(vd, 'commercials', 'searchFilter')"
            v-for="vd in commercials"
            closable
          >
            {{ vd.name }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-setting" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="
            custom-table-style
            table-configuration-variable-style table-header
          "
          :items="getAllConfigurations"
          :fields="fields"
          bordered
          sticky-header
          hover
          :style="sizeTable"
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.configs.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(name)="data">{{ data.item.name }}</template>

          <template #row-details="data">
            <span
              v-for="(item, index) in data.item.configs"
              :key="data.item + 'config' + index"
            >
              <template>
                <span class="align-td-item background-week">
                  <td class="item-details-style col-check-obligee">
                    <span></span>
                  </td>
                  <td class="item-details-style td-col-client">
                    <div
                      v-if="
                        item.main_family && item.main_family != 'commercial'
                      "
                    >
                      {{ item.filiale_name }}
                    </div>
                    <div v-else class="ml-2 text-left">
                      <span
                        v-for="i in item.commercials"
                        :key="'commercial' + i.id"
                        >- {{ i.commercial }}</span
                      >
                    </div>
                  </td>
                  <td class="item-details-style">
                    {{ item.description ? item.description : '-' }}
                  </td>
                  <td class="item-details-style">
                    {{ item.coef }}
                  </td>
                  <td class="item-details-style">
                    {{ item.label }}
                  </td>
                  <td class="item-details-style">
                    {{ item.date_debut }}
                  </td>
                  <td class="item-details-style">
                    {{ item.date_fin ? item.date_fin : '-' }}
                  </td>
                  <td class="item-details-style">
                    {{ item.main_family ? item.main_family : '-' }}
                  </td>
                  <td class="item-details-style">
                    {{ item.user ? item.user : '-' }}
                  </td>
                  <td class="item-details-style td-col-actions">
                    <b-button
                      v-if="checkPermission('GLCTHDC')"
                      class="button-danger-style ml-2"
                      size="sm"
                      variant="info"
                      @click="duplicateConfiguration(item)"
                    >
                      <font-awesome-icon icon="copy" />
                    </b-button>
                    <b-button
                      v-if="checkPermission('GLCTHMC')"
                      class="button-succes-style ml-2"
                      size="sm"
                      variant="succes"
                      @click="updateConfigurationItem(item)"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </b-button>
                    <b-button
                      v-if="checkPermission('GLCTHVC')"
                      class="ml-2 button-clocked-style"
                      size="sm"
                      variant="succes"
                      @click="BlockUnblockConfigclick(item)"
                    >
                      <font-awesome-icon
                        v-if="item.blocked == true"
                        title="Déblocker configuration"
                        icon="eye-slash"
                        class="fa-2x bell1"
                      />

                      <font-awesome-icon
                        v-if="item.blocked == false"
                        title="blocker configuration"
                        icon="eye"
                        class="fa-2x bell1"
                      />
                    </b-button>
                  </td>
                </span>
              </template>
            </span>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowConf"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="updateConfigurationModal"
      id="updateConfigurationModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
    >
      <div class="hader mb-2">
        <div class="ml-4">
          Modifier la configuration de la sociéte
          <b>
            {{
              configurationToUpdate && configurationToUpdate.filiale_name
                ? configurationToUpdate.filiale_name
                : '_'
            }}</b
          >
        </div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateConfigurationModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateConfiguration"
            class="form-modal-custom-style"
          >
            <div class="actionModel">
              <div class="messageError">
                <div v-if="getConfigurationError" class="error">
                  <ul
                    v-if="Array.isArray(getConfigurationError)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li
                      v-for="(error, index) in getConfigurationError"
                      :key="index"
                    >
                      {{ error }}
                    </li>
                  </ul>
                  <div v-else>{{ getConfigurationError }}</div>
                </div>
                <div v-if="getErrorForSpecific" class="error">
                  <div class="d-flex flex-row justify-content-center">
                    <p>{{ getErrorForSpecific.error }}</p>
                    <div class="ml-2 bckground-icon-info">
                      <font-awesome-icon
                        class="info-icon"
                        :id="'tooltip-societe'"
                        icon="info"
                      />
                    </div>
                  </div>
                  <b-tooltip :target="'tooltip-societe'" triggers="hover">
                    <ul class="mb-0" style="list-style-type: none">
                      <li
                        v-for="(error, index) in getErrorForSpecific.acheteurs"
                        :key="index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </b-tooltip>
                </div>
              </div>
            </div>
            <div class="row" v-if="configurationToUpdate != null">
              <div class="col-lg-8">
                <div class="ModelCol mr-3">
                  <b-col sm="2" md="2">
                    <b-form-group
                      label="Famille : "
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group
                  ></b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToUpdate.main_family"
                        name="some-radios"
                        value="support"
                        @change="changeInitialFamily"
                        class="check-th-details mt-2 mb-2"
                        >Support</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToUpdate.main_family"
                        name="some-radios"
                        value="master"
                        @change="changeInitialFamily"
                        class="check-th-details mt-2 mb-2"
                        >Master</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToUpdate.main_family"
                        name="some-radios"
                        value="commercial"
                        @change="
                          changeInitialFamily;
                          configurationToUpdate.filiale_id = 291;
                        "
                        class="check-th-details mt-2 mb-2"
                        >Commercial</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToUpdate.main_family == 'commercial'"
                >
                  <div class="col-lg-8">
                    <b-form-group
                      class="famillies-list-label"
                      label-cols-sm="6"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      label="Commercial *  : "
                    >
                      <multiselect
                        v-model="configurationToUpdate.commercials"
                        :options="computedCommercial"
                        :multiple="true"
                        label="name"
                        track-by="id"
                        placeholder="sélectionner un commercial"
                        :showLabels="false"
                        class="multiselect-vue-custom-style-theme"
                        :loading="loadingFiliale"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        name="flavour-1"
                        id="checkbox-group-filter"
                        class="check-th"
                        @change="SelectAllUpdateCommercials($event)"
                        :checked="
                          computedSelectAllComercials(
                            configurationToUpdate.commercials
                          )
                        "
                      >
                        Tous
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToUpdate.main_family == 'support'"
                >
                  <div class="col-lg-8">
                    <b-form-group
                      class="famillies-list-label"
                      label-cols-sm="6"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      label="Famille Support: "
                    >
                      <multiselect
                        v-model="configurationToUpdate.famillies"
                        :options="getListFamilleConfigFrias"
                        :multiple="true"
                        label="familly"
                        track-by="id"
                        placeholder="sélectionner une famille"
                        required
                        :showLabels="false"
                        :loading="getLoadingFamilleConfig"
                        class="multiselect-vue-custom-style-theme"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        name="flavour-1"
                        id="checkbox-group-filter"
                        class="check-th"
                        @change="SelectAllFamillesUpdate($event)"
                        :checked="
                          computedSelectAllFamilles(
                            configurationToUpdate.famillies
                          )
                        "
                      >
                        Tous
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                  <div class="col-lg-2">
                    <b-form-group
                      label=""
                      label-for=""
                      class="input-modal-champ mb-0"
                    >
                      <button
                        type="button"
                        v-b-modal.addConfigurationFamilleFraisModal
                        class="ml-2 btn-add-anay-prop mt-2"
                        title="Ajouter une famille"
                      >
                        <font-awesome-icon
                          icon="plus"
                          class="icon-plus-ciston-config-dync"
                        />
                      </button>
                    </b-form-group>
                  </div>
                </div>
                <div class="m-0"><hr /></div>
                <div class="ModelCol mr-3">
                  <b-col sm="3" md="3">
                    <b-form-group
                      label="Regroupement par : "
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group
                  ></b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToUpdate.grouped"
                        name="some-radios"
                        @change="stateInitial"
                        value="dossier"
                        class="check-th-details mb-1 ml-2"
                        >Par dossier</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="2" md="2">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToUpdate.grouped"
                        name="some-radios"
                        value="groupe"
                        class="check-th-details mb-1 ml-2"
                        @change="stateInitial"
                        >Par groupe</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToUpdate.grouped == 'groupe'"
                >
                  <b-form-group
                    label="Grouper par"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <select-simple-with-search
                      :value="configurationToUpdate.group_by"
                      :options="computedGetColumns"
                      placeholder="Groupe par"
                      @changeValue="configurationToUpdate.group_by = $event"
                      label="value"
                      trackBy="key"
                      noResult="Aucun option trouvé."
                    />
                  </b-form-group>
                  <!-- bureau d'etude -->
                  <b-form-group
                    label="Facture grouper par :"
                    label-for="be-name"
                    class="input-modal-champ mb-0"
                  >
                    <select-simple-with-search
                      :value="configurationToUpdate.facture_group_by"
                      :options="computedGetColumns"
                      placeholder="Facture groupe par"
                      @changeValue="
                        configurationToUpdate.facture_group_by = $event
                      "
                      label="value"
                      trackBy="key"
                      noResult="Aucun option trouvé."
                    />
                  </b-form-group>
                </div>
                <div class="m-0"><hr /></div>
                <div
                  class="ModelCol mr-3"
                  v-if="configurationToUpdate.main_family == 'commercial'"
                >
                  <b-col sm="2" md="2">
                    <b-form-group
                      label="Appel à paiement : "
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                    </b-form-group
                  ></b-col>
                  <b-col sm="3" md="3">
                    <b-form-group
                      label=""
                      label-for="month"
                      class="input-modal-champ type-doc-class"
                    >
                      <b-form-checkbox
                        v-model="configurationToUpdate.payment_call"
                        name="some-radios"
                        class="check-th-details mb-1 ml-2"
                        >Appel à paiement</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col sm="7" md="7"></b-col>
                </div>
                <div
                  class="m-0"
                  v-if="configurationToUpdate.main_family == 'commercial'"
                >
                  <hr />
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Société à configurer"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <select-simple-with-search
                      :value="configurationToUpdate.filiale_id"
                      :options="computedNotCommercial"
                      placeholder="sélectionner une Sociéte"
                      @changeValue="
                        configurationToUpdate.filiale_id = $event;
                        checkErrorsForUpdate();
                      "
                      :disabled="
                        configurationToUpdate.main_family == 'commercial'
                          ? true
                          : false
                      "
                    />
                  </b-form-group>
                  <b-form-group
                    label="Type"
                    label-for="be-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="be-name"
                      v-model="configurationToUpdate.acheteur_type"
                      value-field="id"
                      text-field="name"
                      :options="acheteur_type_list"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Client"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                    v-if="configurationToUpdate.acheteur_type == 'frais'"
                  >
                    <select-simple-with-search
                      :value="configurationToUpdate.acheteur_id"
                      :options="computedNotCommercial"
                      placeholder="sélectionner un client"
                      @changeValue="
                        configurationToUpdate.acheteur_id = $event;
                        checkErrorsForUpdate();
                      "
                      noResult="Aucun client trouvé."
                    />
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Début de configuration"
                    label-for="Début de configuration"
                    class="input-modal-champ mb-0 pl-0"
                  >
                    <date-picker
                      format="DD-MM-YYYY"
                      value-type="format"
                      type="date"
                      v-model="configurationToUpdate.date_debut"
                      :clear-button="true"
                      :disabled-date="disabledStartDateUpdate"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group
                    label="Fin du configuration"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <date-picker
                      format="DD-MM-YYYY"
                      value-type="format"
                      type="date"
                      v-model="configurationToUpdate.date_fin"
                      :clear-button="true"
                      :disabled-date="disabledEndDateUpdate"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="TVA"
                    label-for="TVA"
                    class="input-modal-champ mb-0 col-4 pl-0"
                  >
                    <b-form-select
                      id="tva"
                      v-model="configurationToUpdate.tva"
                      required
                      :options="getAllTvas"
                      class="b-form-select-raduis"
                      text-field="valeur"
                      value-field="valeur"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TVA %"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label=""
                    label-for=""
                    class="input-modal-champ mb-0 col-2"
                  >
                    <button
                      type="button"
                      v-b-modal.addtvaModalConfigFrais
                      class="ml-2 btn-add-anay-prop"
                      title="Ajouter une nouvelle tva"
                    >
                      <font-awesome-icon
                        icon="plus"
                        class="icon-plus-ciston-config-dync"
                      /></button
                  ></b-form-group>
                  <b-form-group
                    v-if="
                      configurationToUpdate && configurationToUpdate.coef_type
                    "
                    :label="'Mode de calcul'"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0 col-6 pl-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.coef_type"
                      :options="coef_type_reduction_options"
                      required
                      class="b-form-select-raduis"
                      @change="checkErrorsForUpdate"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Description"
                    label-for="desc-config"
                    class="input-modal-champ mb-0"
                  >
                    <b-textarea
                      autocomplete="off"
                      id="desc-config"
                      v-model="configurationToUpdate.description"
                      class="b-form-select-raduis"
                    ></b-textarea>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Facture description"
                    label-for="desc-config"
                    class="input-modal-champ mb-0"
                  >
                    <b-textarea
                      autocomplete="off"
                      id="desc-config"
                      v-model="configurationToUpdate.facture_description"
                      class="b-form-select-raduis"
                    ></b-textarea>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Réduction"
                    label-for="Coefficient"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      v-model="configurationToUpdate.reduction"
                      required
                      type="number"
                      step="any"
                      class="b-form-select-raduis"
                      min="0"
                      oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Type de réduction"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.reduction_type"
                      required
                      :options="coef_type_reduction_options"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    v-if="
                      configurationToUpdate && configurationToUpdate.coef_type
                    "
                    :label="
                      configurationToUpdate.coef_type == 'pourcentage'
                        ? 'Total de Coefficient'
                        : 'Total du Montant'
                    "
                    label-for="Coefficient"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.coef"
                      required
                      type="number"
                      step="any"
                      class="b-form-select-raduis"
                      :disabled="computedDidabledCoeffToUpdate"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Objet"
                    label-for="label"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.label"
                      required
                      class="b-form-select-raduis"
                      @input="checkErrorsForUpdate"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div
                  v-for="(coeff, index) in configurationToUpdate.coefs"
                  :key="index"
                >
                  <div class="ModelCol mr-3">
                    <b-form-group
                      v-if="
                        configurationToUpdate && configurationToUpdate.coef_type
                      "
                      :label="
                        configurationToUpdate.coef_type == 'pourcentage'
                          ? 'Coefficient'
                          : 'Montant'
                      "
                      label-for="Coefficient"
                      class="input-modal-champ mb-0 pl-0 col-6"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.coef"
                        required
                        type="number"
                        step="any"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Label"
                      label-for="label"
                      class="input-modal-champ mb-0 pl-0 col-6"
                    >
                      <!-- <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.label"
                        required
                        class="b-form-select-raduis"
                      ></b-form-input> -->
                      <b-textarea
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.label"
                        required
                        class="b-form-select-raduis"
                      ></b-textarea>
                    </b-form-group>
                    <b-form-group class="input-modal-champ mb-0">
                      <b-button
                        class="button-danger-style ml-1 float-left mt-4"
                        size="sm"
                        variant="danger"
                        v-if="index > 0"
                        @click="deleteCoefficient(index, 'update')"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-form-group>
                  </div>
                </div>
                <div>
                  <b-form-group
                    label=""
                    label-for=""
                    class="input-modal-champ mb-0"
                  >
                    <button
                      type="button"
                      @click="addNewcoeffition('update')"
                      class="ml-2 btn-add-anay-prop"
                    >
                      <font-awesome-icon
                        icon="plus"
                        class="icon-plus-ciston-config-dync"
                      />
                    </button>
                  </b-form-group>
                </div>

                <hr />
                <div
                  v-if="
                    configurationToUpdate.coef_type === 'pourcentage' ||
                      configurationToUpdate.coef_type === 'prix unitaire'
                  "
                >
                  <div class="d-flex justify-content-start text-dark mt-1">
                    <p><b>Formules:</b></p>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-button
                      variant="success"
                      class="config-btn d-flex justify-content-start ml-2"
                      @click="addNewFormule"
                      ><font-awesome-icon icon="plus" /> Ajouter une
                      formule</b-button
                    >
                    <div>
                      <b-form-group
                        v-if="configurationToUpdate.formules.length > 1"
                        label=""
                        label-for=""
                        class="
                          input-modal-champ
                          mb-0
                          ml-2
                          d-flex
                          justify-content-start
                        "
                      >
                        <b-form-select
                          autocomplete="off"
                          id="and_or"
                          v-model="configurationToUpdate.formule_types"
                          required
                          :options="formule_types_options"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group
                        label="Colonne"
                        label-for="colonne"
                        class="
                          input-modal-champ
                          mb-0
                          ml-2
                          d-flex
                          justify-content-start
                        "
                      >
                        <b-form-select
                          autocomplete="off"
                          :id="'condition_type'"
                          @input="CopyFields($event)"
                          :options="getNumeriqCulomn"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div>
                      <div class="CopieMsg" v-if="isTextCopied">
                        Text copier
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(formu, index) in configurationToUpdate.formules"
                    :key="index"
                  >
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Formule"
                        label-for="formule"
                        class="input-modal-champ mb-0 col-lg-11"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="formu.formule"
                          rows="3"
                          required
                          class="b-form-select-raduis"
                          @keyup="
                            checkFormuleConfigFraisDynamic('update', index)
                          "
                        ></b-form-input>
                        <em class="text-danger">{{ formu.error }} </em>
                      </b-form-group>

                      <b-form-group class="input-modal-champ mb-0 col-lg-1">
                        <b-button
                          class="button-danger-style mt-4"
                          size="sm"
                          variant="danger"
                          @click="deleteFormule(index, 'update')"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </b-form-group>
                      <hr />
                    </div>
                  </div>
                  <div v-if="configurationToUpdate.formules.length">
                    <div class="d-flex justify-content-start text-dark mt-3">
                      <p>
                        <b class="mr-2">Formules Finale:</b
                        ><em>
                          {{ computedFormule(configurationToUpdate) }}
                        </em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <p class="d-flex justify-content-start text-dark">
                  <b>Condition de configuration</b>
                </p>
                <div class="ModelCol mr-3">
                  <b-button
                    variant="success"
                    class="config-btn d-flex justify-content-start ml-2"
                    @click="addNewCondition"
                    ><font-awesome-icon icon="plus" /> Ajouter une
                    condition</b-button
                  >
                  <div>
                    <b-form-group
                      v-if="
                        configurationToUpdate &&
                          configurationToUpdate.conditions &&
                          configurationToUpdate.conditions.length > 1
                      "
                      label=""
                      label-for="filiale-name"
                      class="
                        input-modal-champ
                        mb-0
                        ml-2
                        d-flex
                        justify-content-start
                      "
                    >
                      <b-form-select
                        autocomplete="off"
                        id="and_or"
                        v-model="configurationToUpdate.condition_types"
                        required
                        :options="and_or_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div
                  v-for="(condition, index) in configurationToUpdate.conditions"
                  :key="index"
                >
                  <div class="ModelCol mr-3">
                    <p class="m-0" hidden>
                      {{ (condition.index = index) }}
                    </p>
                    <b-form-group
                      label="Colonne"
                      label-for="colonne"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        :value="condition.column"
                        :options="computedGetColumns"
                        placeholder="colonne"
                        @changeValue="
                          condition.column = $event;
                          condition.value = checkColomnConditionConfig(
                            condition.column
                          )
                            ? null
                            : condition.value;
                        "
                        label="value"
                        trackBy="key"
                        :title="conputedSelectedColumn(condition.column)"
                        noResult="Aucun colonne trouvé."
                      />
                    </b-form-group>
                    <b-form-group
                      label="Operation"
                      label-for="Operation"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-name"
                        v-model="condition.operation"
                        required
                        :options="operation_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      v-if="
                        condition.operation != 'ISNULL' &&
                          condition.operation != 'ISNOTNULL'
                      "
                      label="Valeur"
                      label-for="Valeur"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        v-if="checkColomnConditionConfig(condition.column)"
                        :value="condition.value"
                        :options="
                          condition.column == 'organisme'
                            ? getListOrganismeObligee
                            : getProFilials
                        "
                        placeholder="valeur"
                        @changeValue="condition.value = $event"
                        label="name"
                        trackBy="name"
                        noResult="Aucun valeur trouvé."
                      />
                      <b-form-input
                        v-else
                        autocomplete="off"
                        v-model="condition.value"
                        required
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="input-modal-champ mb-0">
                      <b-button
                        class="button-danger-style mt-4"
                        size="sm"
                        variant="danger"
                        @click="deleteCondition(index, 'update')"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-form-group>
                  </div>
                  <hr />
                </div>
                <div
                  v-if="
                    configurationToUpdate.filiale_id != null &&
                      configurationToUpdate.date_debut != null
                  "
                >
                  <div class="d-flex justify-content-start text-dark mt-1">
                    <p><b>Specifics:</b></p>
                  </div>

                  <div class="d-flex justify-content-between">
                    <b-form-checkbox
                      v-model="checkAllUpdate"
                      switch
                      @change="changeAllCheckColumnsUpdate"
                      >Tous</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="specific_master"
                      switch
                      @change="changeMastersFormmat('update')"
                      >Master</b-form-checkbox
                    >
                    <b-form-group
                      label=""
                      label-for="search-add"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="search"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <br />
                  <b-form-group
                    label=""
                    v-slot="{ ariaDescribedby }"
                    class="d-block"
                  >
                    <div class="custom-class-fields-av scroll-specifics-params">
                      <b-form-checkbox
                        v-for="(option, index) in computedSearchListSpecifics"
                        :key="index"
                        v-model="configurationToUpdate.specifics"
                        :aria-describedby="ariaDescribedby"
                        :value="{ filiale_id: option.filiale_id }"
                        @change="checkErrorsForUpdate(option)"
                      >
                        {{ option.name }}
                        <template v-if="specific_master && option.master">
                          <b-form-checkbox
                            v-for="(master, indexM) in option.societe_master"
                            :key="'M' + indexM"
                            v-model="configurationToUpdate.specifics"
                            :aria-describedby="ariaDescribedby"
                            :value="{ filiale_id: master.id }"
                            @change="checkErrorsForAdd"
                          >
                            {{ master.name }}</b-form-checkbox
                          >
                        </template>
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="actionModel">
              <div class="messageError" v-if="errorConfig">
                <div class="error">
                  {{ errorConfig }}
                </div>
              </div>
              <!-- <div class="messageError">
                <div v-if="getConfigurationError" class="error">
                  <ul
                    v-if="Array.isArray(getConfigurationError)"
                    class="mb-0"
                    style="list-style-type: none;"
                  >
                    <li
                      v-for="(error, index) in getConfigurationError"
                      :key="index"
                    >
                      {{ error }}
                    </li>
                  </ul>
                  <div v-else>{{ getConfigurationError }}</div>
                </div>
                <div v-if="getErrorForSpecific" class="error">
                  <div class="d-flex flex-row justify-content-center">
                    <p>{{ getErrorForSpecific.error }}</p>
                    <div class="ml-2 bckground-icon-info ">
                      <font-awesome-icon
                        class="info-icon"
                        :id="'tooltip-societe'"
                        icon="info"
                      />
                    </div>
                  </div>
                  <b-tooltip :target="'tooltip-societe'" triggers="hover">
                    <ul class="mb-0" style="list-style-type: none;">
                      <li
                        v-for="(error, index) in getErrorForSpecific.acheteurs"
                        :key="index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </b-tooltip>
                </div>
              </div> -->
              <b-button
                type="submit"
                class="button-valide-style"
                :disabled="getIsLoadingConfigFraisTh"
              >
                <!-- !validateToUpdateconf || -->
                <span>
                  Valider
                  <div v-if="getIsLoadingConfigFraisTh" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import domain from '@/environment';
import FilterComponent from '@/views/ThProject/frais/component/filterComponent.vue';
import SelectSimpleWithSearch from '../../component/selectSimpleWithSearch.vue';

export default {
  data() {
    return {
      specific_master: false,
      specific_master_dup: false,
      active_date: null,
      active: null,
      commercials: [],
      famille: null,
      vendeurs: null,
      errorConfig: null,
      acheteur_type_list: [
        { id: 'frais', name: 'frais' },
        { id: "bureau d'etude", name: "Bureau d'etude" }
      ],
      search: null,
      baseURL: domain,
      isTextCopied: false,
      formule_types_options: [
        { text: 'Min', value: 'min' },
        { text: 'Max', value: 'max' }
      ],
      coef_type_reduction_options: [
        { text: 'Pourcentage', value: 'pourcentage' },
        { text: 'Montant fix', value: 'montant fix' },
        { text: 'Prix unitaire', value: 'prix unitaire' }
      ],
      and_or_options: [
        { text: 'OU', value: 'or' },
        { text: 'ET', value: 'and' }
      ],
      operation_options: [
        {
          text: 'Egale à',
          value: '=='
        },
        {
          text: 'Pas égale à',
          value: '!='
        },
        {
          text: 'Supérieur à',
          value: '>'
        },
        {
          text: 'Inférieur à',
          value: '<'
        },
        {
          text: 'Superieur ou égale à',
          value: '>='
        },
        {
          text: 'Inferieur ou égale à',
          value: '<='
        },
        {
          text: 'Contient',
          value: 'str_contains'
        },
        {
          text: 'ne contient pas',
          value: '!str_contains'
        },
        {
          text: 'est null',
          value: 'ISNULL'
        },
        {
          text: "n'est pas null",
          value: 'ISNOTNULL'
        }
      ],
      fields: [
        {
          key: 'details',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee'
        },
        {
          key: 'name',
          label: 'Societé',
          thClass: 'th-col-client',
          tdClass: 'td-col-client'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'coef',
          label: 'Coefficient'
        },
        {
          key: 'label',
          label: 'Label'
        },

        {
          key: 'date_debut',
          label: 'Début de configuration'
        },
        {
          key: 'date_fin',
          label: 'Fin de configuration'
        },
        {
          key: 'main_family',
          label: 'Famille'
        },
        {
          key: 'user',
          label: 'Créer par'
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'th-col-actions',
          tdClass: 'td-col-actions'
        }
      ],
      validateToUpdateconf: false,
      validateToAddconf: false,
      checkAll: false,
      checkAllUpdate: false,
      configurationToAdd: {
        commercials: [],
        main_family: null,
        filiale_id: null,
        grouped: 'dossier',
        group_by: null,
        facture_group_by: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: 'pourcentage',
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [
          {
            coef: 0,
            label: null
          }
        ],
        conditions: [],
        specifics: [],
        formules: [{ formule: null }],
        acheteur_type: 'frais',
        description: null,
        facture_description: null,

        famillies: []
      },
      dateEndForSearch: null,
      dateStartforSearch: null,
      nom_societe: null,
      configuration: null,
      configurationToUpdate: null,
      perPage: 20,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getAllFilialSupport',
      'getAllProFilial',
      'getAllNumColums',
      'getAllColumns',
      'addNewConfiguration',
      'checkPeriod',
      'fetchAllconfigurations',
      'updateConfiguration',
      'initErrors',
      'BlockUnblockConfigFraisDync',
      'fetchAllFammilleConfig',
      'checkFormuleConfigFraisDynamicMethod',
      'getFamilles'
    ]),
    SelectAllCommercials(check) {
      if (check == true) {
        this.configurationToAdd.commercials = this.computedCommercial;
      } else {
        this.configurationToAdd.commercials = [];
      }
    },
    SelectAllUpdateCommercials(check) {
      if (check == true) {
        this.configurationToUpdate.commercials = this.computedCommercial;
      } else {
        this.configurationToUpdate.commercials = [];
      }
    },
    SelectAllFamilles(check) {
      if (check == true) {
        this.configurationToAdd.famillies = this.getListFamilleConfigFrias;
      } else {
        this.configurationToAdd.famillies = [];
      }
    },
    SelectAllFamillesUpdate(check) {
      if (check == true) {
        this.configurationToUpdate.famillies = this.getListFamilleConfigFrias;
      } else {
        this.configurationToUpdate.famillies = [];
      }
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    initialFamilly() {
      if (this.configurationToAdd.main_family == false) {
        this.configurationToAdd.main_family = 'support';
      }
    },
    changeInitialFamily() {
      if (this.configurationToUpdate.main_family == false) {
        this.configurationToUpdate.main_family = 'support';
      }
    },
    changeState() {
      if (this.configurationToAdd.grouped == false) {
        this.configurationToAdd.grouped = 'dossier';
      }
    },
    stateInitial() {
      if (this.configurationToUpdate.grouped == false) {
        this.configurationToUpdate.grouped = 'dossier';
      }
    },
    async checkFormuleConfigFraisDynamic(type, index) {
      let value =
        type === 'add'
          ? this.configurationToAdd.formules[index].formule
          : this.configurationToUpdate.formules[index].formule;
      const response = await this.checkFormuleConfigFraisDynamicMethod({
        formule: value
      });
      if (response && response.success == false) {
        if (type === 'add') {
          this.configurationToAdd.formules[index].error = response.error;
        } else {
          this.configurationToUpdate.formules[index].error = response.error;
        }
      } else {
        if (type === 'add') {
          this.configurationToAdd.formules[index].error = null;
        } else {
          this.configurationToUpdate.formules[index].error = null;
        }
      }
    },
    async handelFilter() {
      await this.fetchAllconfigurations({
        date_debut: this.dateStartforSearch,
        date_fin: this.dateEndForSearch,
        search: this.nom_societe,
        per_page: this.perPage,
        page: this.page,
        vendeurs: this.vendeurs,
        active: this.active,
        active_date: this.active_date,
        famille: this.famille,
        commercials: this.commercials
      });
    },
    openModalConfig() {
      this.checkAll = true;
      this.specific_master = false;
      this.changeAllCheckColumns();
      this.fetchAllFammilleConfig({});
    },
    async searchFilter(e, colonne) {
      if (e && colonne) {
        this[colonne] = e;
      }

      this.handelFilter();
    },
    CopyFields(payload) {
      var textarea = document.createElement('textarea');
      textarea.textContent = '$' + payload;
      document.body.appendChild(textarea);
      var selection = document.getSelection();
      var range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      this.isTextCopied = true;
      setTimeout(() => {
        this.isTextCopied = false;
      }, 800);
    },
    changeAllCheckColumns() {
      let table = [];
      if (this.checkAll) {
        if (this.specific_master) {
          this.computedNotCommercial
            .filter(i => i.master)
            .map(item => {
              table.push({
                filiale_id: item.id
              });
              item.societe_master.map(m =>
                table.push({
                  filiale_id: m.id
                })
              );
            });
        } else {
          table = this.computedNotCommercial.map(item => ({
            filiale_id: item.id
          }));
        }
      } else {
        if (this.specific_master) {
          let masterIds = this.computedNotCommercial
            .filter(i => i.master)
            .map(i => i.societe_master)
            .flat()
            .map(i => i.id);
          table = this.computedNotCommercial
            .filter(i => !masterIds.includes(i.id) && !i.master)
            .map(i => ({ filiale_id: i.id }));
        }
      }
      this.configurationToAdd.specifics = table;
    },
    changeMastersFormmat(type) {
      if (this.specific_master == true) {
        if (type == 'add') {
          this.checkAll = false;
          this.configurationToAdd.specifics = [];
        } else if (type == 'update') {
          this.checkAllUpdate = false;
          this.configurationToUpdate.specifics = [];
        }
      }
    },
    changeAllCheckColumnsUpdate() {
      let table = [];
      if (this.checkAllUpdate) {
        if (this.specific_master) {
          this.computedNotCommercial
            .filter(i => i.master)
            .map(item => {
              table.push({
                filiale_id: item.id
              });
              item.societe_master.map(m =>
                table.push({
                  filiale_id: m.id
                })
              );
            });
        } else {
          table = this.computedNotCommercial.map(item => ({
            filiale_id: item.id
          }));
        }
      } else {
        if (this.specific_master) {
          let masterIds = this.computedNotCommercial
            .filter(i => i.master)
            .map(i => i.societe_master)
            .flat()
            .map(i => i.id);
          table = this.computedNotCommercial
            .filter(i => !masterIds.includes(i.id) && !i.master)
            .map(i => ({ filiale_id: i.id }));
        }
      }
      this.configurationToUpdate.specifics = table;
    },
    addNewCondition(type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      } else {
        this.configurationToUpdate.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      }
    },
    addNewFormule(type) {
      if (type === 'add') {
        this.configurationToAdd.formules.push({
          formule: null,
          error: null
        });
      } else {
        this.configurationToUpdate.formules.push({
          formule: null,
          error: null
        });
      }
    },
    addNewcoeffition(type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.push({
          coef: 0,
          label: null
        });
      } else {
        this.configurationToUpdate.coefs.push({
          coef: 0,
          label: null
        });
      }
    },
    deleteCondition(index, type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.splice(index, 1);
      } else {
        this.configurationToUpdate.conditions.splice(index, 1);
      }
    },
    deleteFormule(index, type) {
      if (type === 'add') {
        this.configurationToAdd.formules.splice(index, 1);
      } else {
        this.configurationToUpdate.formules.splice(index, 1);
      }
    },
    deleteCoefficient(index, type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.splice(index, 1);
      } else {
        this.configurationToUpdate.coefs.splice(index, 1);
      }
    },
    resetModal() {
      this.errorConfig = null;
      this.search = null;
      this.configurationToAdd = {
        commercials: [],
        main_family: null,
        grouped: null,
        group_by: null,
        facture_group_by: null,
        filiale_id: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: 'pourcentage',
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [
          {
            coef: 0,
            label: null
          }
        ],
        conditions: [],
        specifics: [],
        formules: [],
        acheteur_type: 'frais',
        description: null,
        facture_description: null
      };
      this.configurationToUpdate = null;
      this.initErrors();
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handelFilter();
    },
    async changePerPage() {
      this.page = 1;
      this.handelFilter();
    },

    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async updateConfigurationItem(data) {
      this.configuration = data;

      this.configurationToUpdate = JSON.parse(JSON.stringify(data));
      this.configurationToUpdate.specifics = this.configurationToUpdate?.specifics?.map(
        item => ({
          filiale_id: item.filiale_id
        })
      );
      this.configurationToUpdate.commercials = this.configurationToUpdate.commercials.map(
        item => ({
          id: item.commercial_id,
          is_societe_support: 0,
          master: false,
          societe_master: [],
          type: 'particulier',
          name: item.commercial
        })
      );
      this.checkAllUpdate =
        this.configurationToUpdate.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;
      this.fetchAllFammilleConfig({});
      this.$refs['updateConfigurationModal'].show();
      // if (this.configurationToUpdate.date_debut != null) {
      //   this.configurationToUpdate.date_debut = moment(
      //     this.configurationToUpdate.date_debut,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }

      // if (this.configurationToUpdate.date_fin != null) {
      //   this.configurationToUpdate.date_fin = moment(
      //     this.configurationToUpdate.date_fin,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }

      let objToSend = {
        id: this.configurationToUpdate.id,
        filiale_id: this.configurationToUpdate.filiale_id,
        date_debut:
          this.configurationToUpdate.date_debut != null
            ? moment(
                this.configurationToUpdate.date_debut,
                'DD-MM-YYYY'
              ).format('YYYY-MM-DD')
            : this.configurationToUpdate.date_debut,
        date_fin:
          this.configurationToUpdate.date_fin != null
            ? moment(this.configurationToUpdate.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToUpdate.date_fin,
        specific_ids: [],
        label: this.configurationToUpdate.label,
        acheteur_id: this.configurationToUpdate.acheteur_id,
        coef_type: this.configurationToUpdate.coef_type
      };
      this.configurationToUpdate.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToUpdate.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToUpdateconf = true;
        } else {
          this.validateToUpdateconf = false;
        }
      }
    },
    async BlockUnblockConfigclick(item) {
      await this.BlockUnblockConfigFraisDync(item);
    },
    async handleUpdateConfiguration() {
      this.errorConfig = null;
      if (
        this.configurationToUpdate.famillies.length == 0 &&
        this.configurationToUpdate.main_family == 'support'
      ) {
        this.errorConfig = 'sélectionne au moins une famille';
      } else {
        this.configurationToUpdate.grouped =
          this.configurationToUpdate.grouped == 'dossier' ? 'non' : 'oui';
        this.configurationToUpdate.payment_call =
          this.configurationToUpdate.payment_call == true ? 'oui' : 'non';
        this.configurationToUpdate.reduction = parseFloat(
          this.configurationToUpdate.reduction
        );
        const response = await this.updateConfiguration({
          configuration: this.configuration,
          configurationToUpdate: this.configurationToUpdate
        });
        if (response.success) {
          this.hideModal('updateConfigurationModal');
        }
      }
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        date >= new Date(this.configurationToAdd.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        date < new Date(this.configurationToAdd.date_debut)
      );
    },

    disabledStartDateFilter(date) {
      return (
        this.dateEndForSearch &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.dateEndForSearch)
      );
    },
    disabledEndDateFiltre(date) {
      return (
        this.dateStartforSearch &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.dateStartforSearch)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.configurationToUpdate.date_fin &&
        date >= new Date(this.configurationToUpdate.date_fin)
      );
    },
    disabledEndDateUpdate(date) {
      return (
        this.configurationToUpdate.date_debut &&
        date < new Date(this.configurationToUpdate.date_debut)
      );
    },
    async duplicateConfiguration(data) {
      this.configurationToAdd = JSON.parse(JSON.stringify(data));
      this.configurationToAdd.specifics = this.configurationToAdd.specifics.map(
        item => ({
          filiale_id: item.filiale_id
        })
      );
      this.configurationToAdd.commercials = this.configurationToAdd.commercials.map(
        item => ({
          id: item.commercial_id,
          is_societe_support: 0,
          master: false,
          societe_master: [],
          type: 'particulier',
          name: item.commercial
        })
      );
      this.configurationToAdd = this.configurationToAdd;
      this.checkAll =
        this.configurationToAdd.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;
      this.fetchAllFammilleConfig({});
      this.$refs['addConfigurationModal'].show();
      // if (this.configurationToAdd.date_debut != null) {
      //   this.configurationToAdd.date_debut = moment(
      //     this.configurationToAdd.date_debut,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }
      // if (this.configurationToAdd.date_fin != null) {
      //   this.configurationToAdd.date_fin = moment(
      //     this.configurationToAdd.date_fin,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }

      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id?.id,
        date_debut:
          this.configurationToAdd.date_debut != null
            ? moment(this.configurationToAdd.date_debut, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_debut,
        date_fin:
          this.configurationToAdd.date_fin != null
            ? moment(this.configurationToAdd.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_fin,
        specific_ids: [],
        acheteur_id: this.configurationToAdd.acheteur_id,
        coef_type: this.configurationToAdd.coef_type
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToAddconf = true;
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    async handleSubmitConfiguration() {
      this.errorConfig = null;
      if (
        this.configurationToAdd.famillies.length == 0 &&
        this.configurationToAdd.main_family == 'support'
      ) {
        this.errorConfig = 'sélectionne au moins une famille';
      } else if (
        this.configurationToAdd.famillies.length == 0 &&
        this.configurationToAdd.main_family == 'support'
      ) {
        this.errorConfig = 'sélectionne au moins une famille';
      } else {
        this.configurationToAdd.grouped =
          this.configurationToAdd.grouped == 'dossier' ? 'non' : 'oui';
        this.configurationToAdd.payment_call =
          this.configurationToAdd.payment_call == true ? 'oui' : 'non';
        this.configurationToAdd.reduction = parseFloat(
          this.configurationToAdd.reduction
        );
        const response = await this.addNewConfiguration(
          this.configurationToAdd
        );
        if (response?.success) {
          this.hideModal('addConfigurationModal');
        }
      }
    },
    async checkErrorsForAdd(option) {
      if (option && option.master) {
        let testTable = this.configurationToAdd.specifics.map(
          i => i.filiale_id
        );
        option.societe_master.forEach(i => {
          if (!testTable.includes(i.id)) {
            this.configurationToAdd.specifics.push({ filiale_id: i.id });
          }
        });
      }
      this.checkAll =
        this.configurationToAdd.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;

      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id,
        date_debut:
          this.configurationToAdd.date_debut != null
            ? moment(this.configurationToAdd.date_debut, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_debut,
        date_fin:
          this.configurationToAdd.date_fin != null
            ? moment(this.configurationToAdd.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_fin,
        specific_ids: [],
        acheteur_id: this.configurationToAdd.acheteur_id,
        coef_type: this.configurationToAdd.coef_type
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToAddconf = true;
          this.initErrors();
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    async checkErrorsForUpdate(option) {
      if (option && option.master) {
        let testTable = this.configurationToUpdate.specifics.map(
          i => i.filiale_id
        );
        option.societe_master.forEach(i => {
          if (!testTable.includes(i.id)) {
            this.configurationToUpdate.specifics.push({ filiale_id: i.id });
          }
        });
      }
      this.checkAllUpdate =
        this.configurationToUpdate.specifics.length ==
        this.computedFormatFilialList.length
          ? true
          : false;
      let objToSend = {
        id: this.configurationToUpdate.id,
        filiale_id: this.configurationToUpdate.filiale_id,
        date_debut:
          this.configurationToUpdate.date_debut != null
            ? moment(
                this.configurationToUpdate.date_debut,
                'DD-MM-YYYY'
              ).format('YYYY-MM-DD')
            : this.configurationToUpdate.date_debut,
        date_fin:
          this.configurationToUpdate.date_fin != null
            ? moment(this.configurationToUpdate.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToUpdate.date_fin,
        specific_ids: [],
        label: this.configurationToUpdate.label,
        acheteur_id: this.configurationToUpdate.acheteur_id,
        coef_type: this.configurationToUpdate.coef_type
      };
      this.configurationToUpdate.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToUpdate.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToUpdateconf = true;
          this.initErrors();
        } else {
          this.validateToUpdateconf = false;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getFilialsSupport',
      'getProFilials',
      'getNumeriqCulomn',
      'getColumns',
      'getIsLoadingConfigFraisTh',
      'getConfigurationError',
      'getAllConfigurations',
      'getTotalRowConf',
      'getErrorForSpecific',
      'getListFamilleConfigFrias',
      'getLoadingFamilleConfig',
      'getAllTvas',
      'checkPermission',
      'familles',
      'getListOrganismeObligee',
      'loadingFiliale'
    ]),

    computedSelectAllComercials() {
      return function(commercial) {
        if (commercial.length == this.computedCommercial.length) {
          return true;
        }
        return false;
      };
    },
    computedCommercial() {
      return this.getProFilials.filter(i => i.type == 'particulier');
    },
    computedNotCommercial() {
      return this.getProFilials.filter(i => i.type != 'particulier');
    },
    computedGetColumns() {
      return Object.keys(this.getColumns).map(i => {
        return { value: this.getColumns[i], key: i };
      });
    },
    checkColomnConditionConfig() {
      return function(value) {
        return (
          value &&
          (value == 'installeur' ||
            value == 'organisme' ||
            value == 'regie' ||
            value == 'sous_regie' ||
            value == 'super_regie')
        );
      };
    },
    computedListFamille() {
      return this.familles.map(i => {
        return { value: i, text: i };
      });
    },
    conputedSelectedColumn() {
      return function(key) {
        return key != null ? this.getColumns[key] : '';
      };
    },
    computedSelectAllFamilles() {
      return function(familles) {
        if (familles?.length == this.getListFamilleConfigFrias?.length) {
          return true;
        }
        return false;
      };
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 340 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 250 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },

    computedCheckChipFilter() {
      if (this.vendeurs?.length > 0) {
        return true;
      }
      return false;
    },
    computedFormule() {
      return function(data) {
        if (data?.formules?.length) {
          let formule = '';
          formule =
            formule + data?.formules?.length == 1
              ? ''
              : data.formule_types + '(';
          data.formules.forEach((element, index) => {
            index < data.formules.length - 1
              ? (formule = formule + '(' + element.formule + ')' + ',')
              : (formule = formule + '(' + element.formule + ')');
          });
          data?.formules?.length == 1
            ? (formule = formule + '')
            : (formule = formule + ')');
          Object.keys(this.getNumeriqCulomn).forEach(key => {
            formule = formule.replaceAll('$' + key, this.getNumeriqCulomn[key]);
          });
          return formule;
        }
        return '';
      };
    },
    computedFormatFilialList() {
      return this.getProFilials
        .filter(i => i.type != 'particulier')
        .map(item => ({
          ...item,
          filiale_id: item.id
        }));
    },
    computedSearchListSpecifics() {
      let table = this.specific_master
        ? this.computedFormatFilialList.filter(
            i => i.master == this.specific_master
          )
        : this.computedFormatFilialList;
      if (this.search != null) {
        table = table.filter(item =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.master == true) {
        table = table.filter(item => item.master == true);
      }

      return table;
    },
    computedDisabledCoeffition() {
      if (this.configurationToAdd.coefs.length > 0) {
        return true;
      }
      return false;
    },
    computedDidabledCoeffToUpdate() {
      if (this.configurationToUpdate?.coefs?.length > 0) {
        return true;
      }
      return false;
    },
    // computedFitredSociteConfiguer() {
    //   return function(data) {
    //     let tale = this.configurationToAdd.specifics.filter(
    //       item => item.filiale_id != null
    //     );
    //     if (data != null && tale.length) {
    //       tale = tale.filter(item => item.filiale_id != data);
    //     }
    //     let nameOfSpecificsFilials = [];
    //     if (tale.length) {
    //       nameOfSpecificsFilials = tale.map(({ filiale_id }) => filiale_id);
    //     }
    //     if (nameOfSpecificsFilials.length > 0) {
    //       let t = this.getProFilials.filter(
    //         item => !nameOfSpecificsFilials.includes(item.id)
    //       );
    //       return t;
    //     }
    //     return this.getProFilials;
    //   };
    // },
    getComputedConfugiration() {
      return this.configurationToAdd.coefs;
    },
    getComputedConfugirationUpdate() {
      return this.configurationToUpdate?.coefs;
    }
  },
  watch: {
    getComputedConfugiration: {
      handler: function() {
        if (this.configurationToAdd.coefs.length > 0) {
          let sum = 0;
          this.configurationToAdd.coefs.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToAdd.coef = sum;
        }
        // if (this.configurationToAdd.coefs.length === 0) {
        //   this.configurationToAdd.coef = 0;
        // }
      },
      deep: true
    },
    getComputedConfugirationUpdate: {
      handler: function() {
        let copyCoef = this.configurationToUpdate?.coef;
        if (this.configurationToUpdate?.coefs?.length > 0) {
          let sum = 0;
          this.configurationToUpdate?.coefs?.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToUpdate.coef = sum;
        }
        if (this.configurationToUpdate?.coefs?.length === 0) {
          this.configurationToUpdate.coef = copyCoef;
        }
      },
      deep: true
    }
  },
  components: {
    DatePicker,
    Card: () => import('../../component/card.vue'),
    FilterComponent,
    SelectSimpleWithSearch
  },

  async mounted() {
    await this.getFamilles();
    await this.getAllFilialSupport();
    await this.getAllProFilial();
    await this.getAllNumColums();
    await this.getAllColumns();
    this.handelFilter();
  }
};
</script>

<style scoped lang="scss">
.setting-filiale-sci {
  width: 100%;
  .button-danger-style,
  .button-succes-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
    border-radius: 5px;
  }
  .table-configuration-variable-style {
    // max-height: calc(100vh - 304px) !important;
    // height: calc(100vh - 304px) !important;
    // margin-bottom: 0px;
  }
  .button-clocked-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
  }
  .border-raduis {
    border-radius: 50%;
  }
  .input-focus-responsable-depot {
    width: 95%;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
    overflow: visible;
    text-overflow: ellipsis;
    outline: unset;
    padding: 4px;
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 400;

    &:focus {
      background-color: #eeeef7;
    }
  }
  .input-focus-responsable-depot::placeholder {
    color: #c3c3d8;
    font-size: 9px;
  }
  .table-fiche {
    width: 100%;
    height: calc(100vh - 282px);
  }
  .newcolor {
    min-width: 100px;
  }
  .actions {
    position: sticky;
    right: -1px !important;
  }
  .three-points-concats {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
  .back-ground-paire {
    background-color: #8d8cb7 !important;
  }
  .back-ground-impaire {
    background-color: #a0c5e8 !important;
  }
}
.bckground-icon-info {
  height: 22px;
  width: 22px;
  background-color: red;
  border-radius: 20px;
}
.info-icon {
  margin-top: 5px;
  color: #fff;
}

.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.CopieMsg {
  margin-left: 50px;
  color: #4d4bac;
  font-weight: 12px;
}
.custom-class-fields-av {
  .custom-control {
    width: 46%;
  }
}
.custom-class-fields-av-update {
  .custom-control {
    width: 46%;
  }
}
.loadingFetch {
  float: left;
  width: 25px;
  height: 25px;
  font-size: 10px;
}
.widthTitle {
  width: 170px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 250px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.Search-style-component {
  .search-box {
    position: relative;
    top: 0;
    right: 0px;
    background-color: #fdfbfb;
    padding: 5px;
    color: #a2a2a2;
    border: none;
    border-radius: 19px;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: #a2a2a2;
      font-size: 11px;
      // background-color: #f9f9f9;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
// .date-picker-for-filter {
//   height: 30px;
//   border-radius: 0 20px 20px 0;
//   border: 1px solid #8d8cb7;
// }
.xmark-icon {
  color: #000;
  margin-top: 35px;
}
</style>
<style lang="scss">
.mt-21 {
  margin-left: -67px;
}
.table-rapport-style .table-configuration-variable-style {
  .button-warning-style {
    background-color: #c33025;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .button-update-style {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .button-duplicate-style {
    background-color: #17a2b8;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .th-col-client {
    z-index: 10 !important;
    position: sticky !important;
    left: 32px;
  }

  .td-col-client {
    z-index: 5 !important;
    position: sticky !important;
    left: 32px;
  }
  .th-col-actions {
    z-index: 10 !important;
    position: sticky !important;
    right: -10px;
  }

  .td-col-actions {
    z-index: 5 !important;
    position: sticky !important;
    right: -10px;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .td-col-actions,
    .td-col-client,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
.table-fiche::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.table-fiche {
  table {
    display: table !important;
  }
}

.table-fiche::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}

.table-fiche::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.custom-select-vue-component-style {
  .multiselect__tags {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
  }

  .multiselect__input,
  .multiselect__single {
    background: transparent;
  }
}
.labes-depot-name {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  color: #2a2a2a;
  margin-left: 3%;
  margin-top: 38px;
  margin-bottom: 1px;
  width: 30%;
}
.custom-checkbox {
  align-self: center;
  margin-right: 10px;
}
.image-preview {
  position: relative;
  display: inline;
  max-width: 112px;
}
.cutsom-modal-bootstrap-filiale-sci .modal-dialog {
  top: 0%;
  max-width: 70%;
  .type-doc-class > div {
    display: flex;
  }
}
.width-filter {
  width: 110px;
}
.date-picker-for-filter {
  border-radius: 0px 20px 20px 0px !important;
  height: 31px !important;
  border: 1px solid #8d8cb7 !important;
  outline: 0 !important;
  width: 220px !important;
  padding-left: 4px !important;
  color: #000 !important;
  background-color: #fff !important;
}
// .label-box-style {
//   background-color: #8d8cb7;
//   padding: 4px 3px;
//   border-radius: 22px 0px 0px 22px;
//   text-align: end;
//   height: 29px;
// }
.scroll-specifics-params {
  height: calc(100vh - 266px);
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
</style>
