<template>
  <div class="user-setting">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Role</span>
          </div>
          <b-form-select
            class="b-form-select-new-style w-1 bg-select"
            v-model="role"
            :options="[{ text: 'Tous', value: null }, ...listType]"
            @change="FilterGetUser"
          ></b-form-select>
        </div>
        <div>
          <!-- <div v-if="getUserLoading" class=" chargement-message-text ml-2">
            Chargement en cours
          </div> -->
          <div v-if="getUserLoading" class="loading">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
        </div>
        <div class="box-end-position">
          <search
            v-if="checkPermission('GUHTCU')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            classToUse="search-style"
          />
          <b-button
            v-if="checkPermission('GUHTALU')"
            class="active-user-icon ml-2 mr-2"
            variant="outline-secondary"
            @click="handlClickActive('no')"
            pill
            :class="{ 'button-desactiver ': deleted === 'yes' }"
            :pressed="deleted === 'yes'"
            ><font-awesome-icon icon="user"
          /></b-button>
          <b-button
            v-if="checkPermission('GUHTALUS')"
            class="deleted-user-icon"
            variant="outline-secondary"
            @click="handlClickActive('yes')"
            :class="{ 'button-desactiver': deleted === 'no' }"
            :pressed="deleted === 'no'"
            pill
            ><font-awesome-icon icon="user-slash"
          /></b-button>
        </div>
      </div>
    </div>
    <div>
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-user"
          :items="computedUsers"
          :fields="computedListFields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneNormale"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              v-if="getUserData.role == 'admin'"
              class="button-login-style ml-2"
              size="sm"
              variant="success"
              title="Se connecter"
              @click.prevent.stop="handleClickLogin(data.item)"
              :disablled="data.item.loaderChangeCompte"
            >
              <div
                v-if="data.item.loaderChangeCompte"
                class="chargement chargement-custom-user"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <font-awesome-icon icon="arrow-right" v-else />
            </b-button>
            <b-button
              v-if="data.item.deleted == false && checkPermission('GUTHMU')"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
              @click.prevent.stop="handleClickUpdate(data.item)"
              title="Modifier"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>

            <b-button
              v-if="data.item.deleted == false && checkPermission('GUTHSU')"
              class="button-danger-style ml-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="handleClickDelete(data.item)"
              title="Désactiver"
            >
              <font-awesome-icon icon="ban" />
            </b-button>
            <!--  -->
            <b-button
              v-if="data.item.deleted == true && checkPermission('GUTHRU')"
              class="button-warning-style ml-2"
              size="sm"
              variant="warning"
              @click.prevent.stop="handleClickRestaurer(data.item)"
              title="Activer"
            >
              <font-awesome-icon icon="arrow-alt-circle-left" />
            </b-button>
          </template>
          <template v-slot:cell(name)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(email)="data">
            {{ data.item.email }}
          </template>
          <template v-slot:cell(phone)="data">
            {{ data.item.phone }}
          </template>
          <template v-slot:cell(type)="data">
            {{ data.item.type | FiterType }}
          </template>
        </b-table>
        <!-- <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getUsersCount"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div> -->
      </div>
      <div class="footer-style mt-2">
        <b-pagination
          v-model="page"
          :per-page="perPage"
          :total-rows="getUsersTotlaRows"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>

        <div class="per-page-element-style">
          <div class="box-label-champ">
            <div class="label-box-style">
              <span class="title-tabel">Eléments par page</span>
            </div>
          </div>
          <b-form-select
            v-model="perPage"
            :options="perPageList"
            @change="changePerPage"
            class="b-form-select-new-style bg-select"
          ></b-form-select>
        </div>
      </div>
      <!-- update user modal  -->
      <b-modal
        id="userUpdateModal"
        ref="userUpdateModal"
        title="Configuration"
        :hide-footer="true"
        :hide-header="true"
        no-close-on-backdrop
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap"
      >
        <div class="hader mb-3">
          <div class="titleSetting">
            Modifier
            {{ userToUpdate ? userToUpdate.full_name : '' }}
          </div>
          <div class="iconClose" @click.prevent="hideModal('userUpdateModal')">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="handleSubmitUpdate"
              v-if="userToUpdate"
              class="form-modal-custom-style"
            >
              <b-form-group
                label="Nom"
                label-for="nom"
                class="input-modal-champ"
              >
                <b-form-input
                  id="nom"
                  v-model="userToUpdate.full_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="E-mail"
                label-for="email"
                class="input-modal-champ"
              >
                <b-form-input
                  id="tel"
                  v-model="userToUpdate.email"
                  required
                  type="email"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Téléphone"
                label-for="phone"
                class="input-modal-champ"
              >
                <b-form-input
                  id="phone"
                  v-model="userToUpdate.phone"
                  type="tel"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Mot de passe"
                label-for="pasword"
                class="input-modal-champ"
              >
                <b-form-input
                  id="password"
                  v-model="userToUpdate.password"
                  type="password"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Confirmation de mot de passe"
                label-for="confirmationPassword"
                class="input-modal-champ"
              >
                <b-form-input
                  id="confirmationPassword"
                  v-model="userToUpdate.confirmationPassword"
                  type="password"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Profile"
                label-for="profile"
                class="input-modal-champ input-modal-champ-for-search-select"
              >
                <select-simple-with-search
                  :value="userToUpdate.profil_id"
                  :options="computedListeProfile"
                  placeholder="Profile"
                  @changeValue="userToUpdate.profil_id = $event"
                  label="text"
                  trackBy="value"
                  noResult="Aucun profile trouvé."
                />
              </b-form-group>
              <b-form-group
                label="Type"
                label-for="type"
                class="input-modal-champ"
              >
                <b-form-select
                  id="type"
                  v-model="userToUpdate.type"
                  :options="listType"
                  required
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Gestion sociéte à traiter"
                label-for="filiale-responsable"
                class="vue-select-modal-champ"
              >
                <multiselect
                  v-model="userToUpdate.societe_traitement"
                  :options="getGestionSocieteAtraiter"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="name"
                  track-by="id"
                  :preselect-first="false"
                  class="select-vue-component-style mb-4"
                  :showLabels="false"
                >
                  <span slot="noResult">Aucune sociéte trouvé.</span>
                  <span slot="noOptions">Aucune sociéte trouvé.</span>
                </multiselect></b-form-group
              >
              <b-tabs
                v-show="userToUpdate"
                content-class="mt-3"
                fill
                class="mt-3 tabs-class-societ_traiter"
              >
                <b-tab title="Liste des sociétes  à traiter" active>
                  <div v-if="userToUpdate && userToUpdate.societe_traitement">
                    <div
                      v-for="(item, index) in userToUpdate.societe_traitement"
                      :key="'societe-traiter-' + index"
                      class="card-Societe-traiter"
                    >
                      <div>
                        <div class="filiale-list-user">
                          <span>
                            <font-awesome-icon
                              icon="check-double"
                              class="mr-2"
                            />{{ item.name }}</span
                          >
                        </div>
                        <div class="card-societe_traiter-body">
                          <div
                            v-if="
                              computedSelectedSocieteTraite(item.filiales) &&
                                computedSelectedSocieteTraite(item.filiales)
                                  .length
                            "
                          >
                            <div class="title ">Les sociétes</div>
                          </div>
                          <div
                            class="card-societe_traiter-item"
                            v-for="(s, indexS) in computedSelectedSocieteTraite(
                              item.filiales
                            )"
                            :key="indexS + 'soc'"
                          >
                            <div>{{ s.filiale }}</div>
                          </div>
                          <div
                            v-if="
                              computedSelectedSocieteTraite(item.interfaces) &&
                                computedSelectedSocieteTraite(item.interfaces)
                                  .length
                            "
                          >
                            <div class="title">Les interfaces</div>
                          </div>
                          <div
                            class="card-societe_traiter-item"
                            v-for="(i, indexI) in computedSelectedSocieteTraite(
                              item.interfaces
                            )"
                            :key="indexI + 'inter'"
                          >
                            <div>{{ i.interface }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Liste des sociétes">
                  <b-form-group
                    :label="
                      userToUpdate.filiales.length == 0
                        ? 'Aucune sociéte trouvé '
                        : ''
                    "
                    label-for="type"
                    class="input-modal-champ"
                  >
                    <div class="filiale-list-user">
                      <div
                        v-for="(e, index) in userToUpdate.filiales"
                        :key="index"
                      >
                        <span>
                          <font-awesome-icon
                            icon="check-double"
                            class="mr-2"
                          />{{ e.name }}</span
                        >
                      </div>
                    </div>
                  </b-form-group>
                </b-tab>
              </b-tabs>

              <div class="actionModel mt-3">
                <div class="messageError">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button
                  class="button-valide-style"
                  type="submit"
                  :disabled="isLoading"
                >
                  <span>
                    Valider
                    <div v-if="isLoading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div></div
                  ></span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
      <!-- End update user modal  -->
      <!-- Delete User Modal -->
      <b-modal
        ref="userDeleteModal"
        id="userDeleteModal"
        no-close-on-backdrop
        :hide-footer="true"
        title="Supprimer un utilisateur "
        @hidden="hideModal('userDeleteModal')"
      >
        <p>
          Êtes-vous certain de vouloir supprimer l'utilisateur
          <strong class="name">
            {{ userToDelete ? userToDelete.full_name : '' }}
          </strong>
          ?
        </p>
        <div class="form-modal-custom-style">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>
          <div class="actionModel">
            <b-button
              class="button-valide-style"
              @click="handleModalSupprimerValider"
              :disabled="isLoading"
            >
              <span>
                Confirmer
                <div v-if="isLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div></div
              ></span>
            </b-button>
          </div>
        </div>
      </b-modal>
      <!-- END Delete User Modal -->
      <!-- Restaurer User Modal -->
      <b-modal
        ref="userRestaurerModal"
        id="userRestaurerModal"
        no-close-on-backdrop
        title="Restaurer un utilisateur "
        :hide-footer="true"
      >
        <p>
          Êtes-vous sûr de vouloir restaurer cet utilisateur
          <strong class="name">
            {{ userToRestaurer ? userToRestaurer.full_name : '' }}
          </strong>
          ?
        </p>
        <div class="form-modal-custom-style">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>
          <div class="actionModel">
            <b-button
              class="button-valide-style"
              @click="handleRestaurerUser"
              :disabled="isLoading"
            >
              <span>
                Confirmer
                <div v-if="isLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div></div></span
            ></b-button>
          </div>
        </div>
      </b-modal>
      <!-- END Restaurer User Modal -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import SelectSimpleWithSearch from '../../component/selectSimpleWithSearch.vue';
export default {
  data() {
    return {
      searchValue: null,
      isLoading: false,
      userToUpdate: null,
      oldUserToUpdate: null,
      userToDelete: null,
      page: 1,
      perPage: 10,
      showErrorUser: false,
      role: null,
      deleted: 'no',
      userToRestaurer: null,
      loading: null,
      error: null,

      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      sort_by: 'first_name',
      full_name: null,
      listType: [
        { text: 'Comptabilité', value: 'contabilite' },
        { value: 'responsable_filiale', text: 'Responsable Filiale' },
        // { value: 'responsable_novae', text: 'Responsable NOVAE' },
        // { value: 'responsable_cactus', text: 'Responsable CACTUS' },
        // { value: 'responsable_web_abondan', text: 'Responsable PINKMAN' },
        // { value: 'responsable_iso_33', text: 'Responsable KYNAN TECHNIQUE' },
        // { value: 'responsable_rge', text: 'Responsable RGE' },
        // { value: 'responsable_dnh', text: 'Responsable GE CDG' },
        // { value: 'responsable_sav_s', text: 'Responsable SAMI-B' },
        // {
        //   value: 'responsable_web_abondance',
        //   text: 'Responsable WEB ABONDANCE'
        // },
        // { value: 'responsable_corpedys', text: 'Responsable CORPEDYS' },
        // { value: 'responsable_acteo', text: 'Responsable ALTEO FRANCE' },
        // { value: 'responsable_ge_compta', text: 'Responsable GE COMPTA' },
        // { value: 'responsable_ge_rh', text: 'Responsable GE RH' },
        // {
        //   value: 'responsable_bureau_etude',
        //   text: "Responsable Bureau d'etude"
        // },
        // { value: 'responsable_mairie', text: 'Responsable NOVAE MAIRIE' },
        // { value: 'responsable_gse', text: 'Responsable GSE' },
        // { value: 'responsable_artisans', text: 'Responsable Artisans verts' },
        // { value: 'responsable_ge_rh_compta', text: 'Responsable GE RH COMPTA' },
        // {
        //   value: 'responsable_upcube_technologies',
        //   text: 'Responsable UPCUBE TECHNOLOGIES'
        // },
        // {
        //   value: 'responsable_controle_de_gestion',
        //   text: 'Responsable controle de gestion'
        // },
        // {
        //   value: 'responsable_finance',
        //   text: 'Responsable finance'
        // },
        // {
        //   value: 'utilisateur_iso33',
        //   text: 'Utilisateur ISO33'
        // },
        // {
        //   value: 'utilisateur_isodit',
        //   text: 'Utilisateur ISODIT'
        // },
        // {
        //   value: 'utilisateur_effimax',
        //   text: 'Utilisateur EFFIMAX'
        // },
        // {
        //   value: 'utilisateur_CWF_CONSULTING',
        //   text: 'Utilisateur CWF CONSULTING'
        // },
        // {
        //   value: 'responsable_validation',
        //   text: 'Responsable validation'
        // },
        {
          value: 'responsable_support',
          text: 'Responsable Support'
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getUsers',
      'updateUser',
      'deleteUser',
      'restaurerUser',
      'getAllRoles',
      'getTokenAnotherAccount',
      'fetchBadgeNumberReglement'
    ]),
    async handleClickLogin(data) {
      data.loaderChangeCompte = true;
      const response = await this.getTokenAnotherAccount(data.id);
      if (response.success) {
        this.fetchBadgeNumberReglement();
        if (this.checkPermission('SGCDG')) {
          this.$router.push({ name: 'statistics-general' }).catch(() => {});
        } else {
          this.$router.push({ name: 'cdg' }).catch(() => {});
        }
        data.loaderChangeCompte = false;
      }
    },
    async changePerPage() {
      this.page = 1;
      await this.FilterGetUser({ per_page: this.perPage, page: this.page });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.FilterGetUser({ per_page: this.perPage, page: this.page });
    },
    resetModal() {
      this.userToUpdate = null;
      this.oldUserToUpdate = null;
      this.userToDelete = null;
      this.userToRestaurer = null;
      this.loading = null;
      this.error = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    FilterGetUser() {
      this.getUsers({
        role: this.role,
        deleted: this.deleted,
        search: this.searchValue,
        per_page: this.perPage,
        page: this.page
      });
      this.setLocalStorageParamsUsers();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.FilterGetUser();
      this.setLocalStorageParamsUsers();
    },
    handlClickActive(data) {
      this.deleted = data;
      this.FilterGetUser();
      this.setLocalStorageParamsUsers();
    },
    handleChange() {
      this.page = 1;
      this.FilterGetUser();
    },
    handleClickUpdate(user) {
      this.userToUpdate = { ...user };
      this.oldUserToUpdate = user;
      this.$refs['userUpdateModal'].show();
    },
    async handleSubmitUpdate() {
      this.isLoading = true;
      this.error = null;
      const response = await this.updateUser({
        userToUpdate: this.userToUpdate,
        old: this.oldUserToUpdate
      });
      if (response.success) {
        this.isLoading = false;

        this.hideModal('userUpdateModal');
      } else {
        this.isLoading = false;

        this.error = response.error;
      }
    },
    handleClickDelete(user) {
      this.userToDelete = { ...user };
      this.$refs['userDeleteModal'].show();
    },

    async handleModalSupprimerValider() {
      this.isLoading = true;
      this.error = null;
      const response = await this.deleteUser(this.userToDelete.id);
      if (response.success) {
        this.isLoading = false;
        this.hideModal('userDeleteModal');
      } else {
        this.isLoading = false;
        this.error = response.error;
      }
    },
    handleClickRestaurer(user) {
      this.userToRestaurer = user;
      this.$refs['userRestaurerModal'].show();
    },
    async handleRestaurerUser() {
      this.loading = true;
      this.error = null;
      const response = await this.restaurerUser(this.userToRestaurer);
      if (response.success) {
        this.loading = false;
        this.hideModal('userRestaurerModal');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    setLocalStorageParamsUsers() {
      localStorage.setItem(
        'Params-users',
        JSON.stringify({
          role: this.role,
          deleted: this.deleted,
          search: this.searchValue
        })
      );
    }
    // pagination(paginate) {
    //   this.page = paginate;
    //   this.FilterGetUser();
    // },
    // changePerPage() {
    //   this.page = 1;
    //   this.FilterGetUser();
    // }
  },
  components: {
    Card: () => import('../../component/card'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    SelectSimpleWithSearch
  },
  filters: {
    FiterType: value => {
      switch (value) {
        case 'contabilite':
          return 'Comptabilité';
        case 'responsable_dnh':
          return 'Responsable GE CDG';
        case 'responsable_web_abondan':
          return 'Responsable PINKMAN';
        case 'responsable_acteo':
          return ' Responsable ALTEO FRANCE';
        case 'responsable_sav_s':
          return ' Responsable SAMI-B';

        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    }
  },
  mounted() {
    this.getAllRoles();
    if (localStorage.getItem('Params-users')) {
      this.role = JSON.parse(localStorage.getItem('Params-users')).role;
      this.deleted = JSON.parse(localStorage.getItem('Params-users')).deleted;
      this.searchValue = JSON.parse(
        localStorage.getItem('Params-users')
      ).search;
    } else {
      this.setLocalStorageParamsUsers();
    }
    this.FilterGetUser({ per_page: this.perPage, page: this.page });
  },

  computed: {
    ...mapGetters([
      'users',
      'getUserLoading',
      'getUsersCount',
      'getUsersTotlaRows',
      'computedListeProfile',
      'roles',
      'checkPermission',
      'getUserData',
      'getGestionSocieteAtraiter'
    ]),
    computedSelectedSocieteTraite() {
      return function(item) {
        if (item) {
          let tab = [...item];
          return tab.filter(i => i.checked == true);
        }
      };
    },
    computedListFields() {
      let fields = [
        {
          key: 'full_name',
          label: 'Nom',
          show: this.checkPermission('GUHTACN')
        },
        {
          key: 'email',
          label: 'E-mail',
          show: this.checkPermission('GUHTACEM')
        },
        { key: 'type', label: 'Type', show: this.checkPermission('GUHTACTY') },
        {
          key: 'phone',
          label: 'Télephone',
          show: this.checkPermission('GUHTACTT')
        },
        { key: 'Actions', label: '', show: true }
      ];

      return fields.filter(field => field.show == true);
    },
    computedUsers() {
      let deleted = this.deleted == 'yes' ? true : false;
      return this.users.filter(item => item.deleted == deleted);
    },
    computedListeProfile: function() {
      return this.roles.map(role => {
        return {
          text: role.name,
          value: role.id,
          disabled: role.blocked == true ? true : false
        };
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.user-setting {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .table-user {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0px;
    .button-danger-style {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 15px;
      border: none;
    }
    .button-warning-style {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 11px;
      border: none;
    }
  }
  .chargement-message-text {
    text-align: left;
  }
  .active-user-icon {
    color: #ffffff;
    border-color: #28a745;
    background-color: #28a745;
    font-size: 13px;
    padding: 0.4rem 10px;
  }
  .deleted-user-icon {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 0.4rem 8px;
    font-size: 13px;
  }
}
.filiale-list-user {
  text-align: start;
  color: #747070;
  margin-left: 20px;
  font-size: 12.5px;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.card-Societe-traiter {
  // border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
  margin-right: 8px;
  // background-color: #332fc838;
  border-radius: 4px;
  display: table-cell;
  vertical-align: top;
  padding: 2px;
  margin: 5px;
  width: 181px;
  color: #000;
  .card-societe-traiter-header {
    position: relative;
    width: 100%;
    padding: 0px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    background-color: rgb(245 244 250);
    padding: 5px;
  }
  .card-societe_traiter-body {
    margin-left: 41px;
    .card-societe_traiter-item {
      position: relative;
      overflow: visible;
      display: flex;
      justify-content: space-between;
      margin-top: 0px;
      // border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
      padding: 4px;

      width: 100%;
      border-radius: 4px;
      font-size: 9px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: black;
      .icon-flesh {
        cursor: pointer;
        font-size: 10px;
        margin-right: 2px;
        color: #6f6ecb;
        &:hover {
          font-size: 13px;
        }
      }
      .icon-eye {
        cursor: pointer;
        font-size: 10px;
        margin-right: 2px;
        color: #4d4bac;
        &:hover {
          font-size: 13px;
        }
      }
      .conteur-depot-in-week {
        background-color: rgb(54 52 170 / 46%);
        min-width: 17px;
        color: white;
        text-align: center;
        height: 17px;
        border-radius: 14px;
        text-align: center;
        font-size: 9px;
      }
      .input-focus-depot {
        width: 25%;
        background-color: transparent;
        border: none;
        color: #000;
        overflow: visible;
        border: 0;
        font-size: 13px;
        text-overflow: ellipsis;
        outline: unset;
        &:focus {
          background-color: #ededf4;
        }
      }
      .b-form-select-new-style {
        border-radius: 5px;
        width: 110px;
        background-color: #fcfcfc;
        padding-left: 2px;

        padding-top: 0px;

        padding-bottom: 0px;

        height: 20px;
      }
      .box-rigth-details {
        display: flex;
        justify-content: space-between;
      }
    }
    .title {
      text-align: left;
      font-size: 12px;
    }
    .button-add-depot {
      margin: auto;
      margin-top: 5px;
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
.table-user {
  .table {
    display: table;
  }
  td,
  th {
    padding: 5px !important;
  }
}
.input-modal-champ-for-search-select {
  div {
    width: 99%;
  }
}
.tabs-class-societ_traiter {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
</style>
